  <div *ngIf="auth.isAuthenticated$ | async; else notAuthenticated">
  <div>
    <app-sidebar></app-sidebar>
  </div>
</div>
<div #notAuthenticated>
    <app-nav-bar navLight = 'true'/>
      <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
