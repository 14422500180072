import { CommonModule } from '@angular/common';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { MessageSectionComponent } from '../message-section/message-section.component';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css'],
  standalone: true,
  imports: [CommonModule,FontAwesomeModule,MessageSectionComponent]
})
export class HomeContentComponent {
  faLink = faLink;

  isOpen:any = false;

  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

  togggleModal(e:any){
    e.preventDefault();
    this.isOpen = !this.isOpen;
  }

  toggleModal(event: Event): void {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  
    if (this.isOpen) {
      // Reproducir el video cuando se abra el modal
      setTimeout(() => {
        if (this.videoPlayer) {
          this.videoPlayer.nativeElement.play();
        }
      }, 0);
    } else {
      // Pausar el video y reiniciar al cerrar
      if (this.videoPlayer) {
        this.videoPlayer.nativeElement.pause();
        this.videoPlayer.nativeElement.currentTime = 0;
      }
    }
  }

  feature = [
    {
      icon:'uil uil-desktop',
      title:'Desarrollos Web',
      desc:"Construir y mantener sitios web y Web Apps. Funcionamiento rápido y un buen desempeño para permitir la mejor experiencia de usuario."
    },
    {
      icon:'uil uil-forward',
      title:'Software a Medida',
      desc:"Desarrollamos el sistema o software que mejor se adapta a los procesos de gestión que la empresa ya tiene consolidados."
    },
    {
      icon:'uil uil-arrow',
      title:'Erp de Gestión',
      desc:"Implementamos sistemas de gestión ERP para todo tipo de pymes que buscan mejorar su excelencia operativa a través de la automatización de sus procesos de negocio."
    },
    {
      icon:'uil uil-mobile-android',
      title:'App Moviles',
      desc:"Desarrollamos APPS para las plataformas Android y iOS. Apps para publicar y distribuir o apps privadas para empresa optimizando recursos y aumentando la productividad."
    },
  ]
}
