<div class="d-flex">
  <!-- Sidebar -->
  <nav class="sidebar bg-dark text-light p-3" >
    <span class="user-info" *ngIf="auth.user$ | async as user">
      <!-- Profile image should be set to the profile picture from the id token -->
      <img alt="..." class="nav-user-profile d-inline-block rounded-circle mr-3"
         [src]="user.picture" />
      <!-- Show the user's full name from the id token here -->
      <h6 class="d-inline-block">{{ user.name }}</h6>
    </span>
    <ul class="nav flex-column mt-4">
      <li class="nav-item width: 25px;">
        <a routerLink="external-api" class="nav-link">Calendario</a>
      </li>
      <li class="nav-item">
        <!-- Logout link -->
        <a class="nav-link text-light" href="#" (click)="logout()">Logout</a>
      </li>
    </ul>
  </nav>

  <!-- Main Content -->
  <div class="content flex-grow-1 p-3">
    <div class="d-flex flex-column h-100">
      <div class="container flex-grow-1 flex-shrink-1">
        <router-outlet></router-outlet>
      </div>      
      <app-footer></app-footer>
    </div>
  </div>
</div>
