<!-- Footer Start -->
<footer class="footer bg-footer">
  <div class="container">
      <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-0 pb-0 pb-md-2">
              <a class="logo-footer text-light" href="#"><img src="assets/images/cxc-systemLogo-sf.png" height="45" alt=""></a>
              <p class="mt-4">Desarrollamos aplicaciones personalizadas para empresas de todos los tamaños, ofreciendo asesoramiento y mantenimiento adaptado a cada presupuesto.</p>
          </div><!--end col-->

          <div class="col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                      <h4 class="text-light footer-head">Dirección</h4>
                      <p class="text-foot mt-4">Ctra Terrasa 10 Sabadell, 08201 Barcelona</p>
                  </div><!--end col-->
                  <div class="col-lg-4 col-md-4 col-12">
                      <h4 class="text-light footer-head">Teléfono</h4>
                      <p class="text-foot">636 413 166<br>Lunes - Viernes<br>9:00am - 19:00pm</p>
                  </div><!--end col-->
                  <div class="col-lg-4 col-md-4 col-12">
                      <h4 class="text-light footer-head">Contacto</h4>
                      <p class="text-foot">info&#64;cxcsystems.com<br>24 X 7 online support</p>
                  </div><!--end col-->
              </div><!--end row-->
          </div><!--end col-->
      </div><!--end row-->
  </div><!--end container-->
</footer><!--end footer-->
<footer class="footer bg-footer footer-bar">
  <div class="container text-center">
      <div class="row align-items-center">
          <div class="col-sm-6">
              <div class="text-sm-start">
                  <p class="mb-0">© {{date}} <span class="text-reset">cxcSystems</span>. Design & Develop by <a href="https://cxcsystems.com" target="_blank" class="text-reset">cxcSystems</a>.</p>
              </div>
          </div><!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul class="list-unstyled text-sm-end social-icon social mb-0">
                  <li class="list-inline-item m-x-2"><a href="https://www.linkedin.com/company/cxcsystems" target="_blank" class="rounded"><i class="uil uil-linkedin" title="Linkedin"></i></a></li>
                  <li class="list-inline-item m-x-2"><a href="https://www.facebook.com/cxcSystems" target="_blank" class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                  <li class="list-inline-item m-x-2"><a href="https://www.instagram.com/shreethemes/" target="_blank" class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
                  <li class="list-inline-item m-x-2"><a href="https://twitter.com/cxc_systems" target="_blank" class="rounded"><i class="uil uil-twitter align-middle" title="twitter"></i></a></li>
                  <li class="list-inline-item m-x-2"><a href="mailto:cxcsystems@outlook,com" class="rounded"><i class="uil uil-envelope align-middle" title="email"></i></a></li>                                
                  <li class="list-inline-item m-x-2"><a href="https://discord.gg/XnhPKD5W" target="_blank" class="rounded"><i class="uil uil-discord align-middle" title="discord"></i></a></li>
              </ul><!--end icon-->
          </div><!--end col-->
      </div><!--end row-->
  </div><!--end container-->
</footer><!--end footer-->
<!-- Footer End -->
